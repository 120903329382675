import React from "react";
import Layout from "../../components/Layout";
import StudentCategoryList from "../../components/StudentCategoryList";
import { Helmet } from "react-helmet";

export default () => {
  const title = "Arts and Crafts Category | Student Resources";
  const description =
    "Find inspirations for your art and ideas for seasonal crafts that you can do at home with easy-to-find materials.";
  return (
    <Layout>
      <Helmet>
        <title>{title}</title>
        <meta name="description" content={description} />
        <meta property="og:title" content={title} />
        <meta property="og:description" content={description} />
        <meta name="twitter:title" content={title} />
        <meta name="twitter:description" content={description} />
      </Helmet>
      <section className="section">
        <div className="container">
          <div className="content">
            <h1 className="title">Arts and Crafts</h1>
            <p>{description}</p>
            <StudentCategoryList category="arts-and-crafts" />
          </div>
        </div>
      </section>
    </Layout>
  );
};
